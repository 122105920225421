import Head from 'next/head'
import favicon from '../public/favicon-32x32.png'
import faviconDev from '../public/favicon-dev.ico'
import piboxBoard from '../assets/pibox-hero-white-with-pi-white-small.png'
import { COMMIT_HASH, SITE_TITLE_PREFIX } from '../config'

export function CommonHead({
  title = 'KubeSail',
  description = 'The Self Hosting Company, makers of the PiBox! Run a server in your home or office that runs apps, saves money, and respects your privacy.',
  keywords = [],
  url = 'https://kubesail.com/homepage',
  image = piboxBoard.src,
  imageWidth = 607,
  imageHeight = 369,
}) {
  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="shortcut icon" href={COMMIT_HASH !== 'dev' ? favicon.src : faviconDev.src} />
      <link rel="canonical" href={url} />
      <title>{`${SITE_TITLE_PREFIX} ${title}`}</title>
      <meta key="title" property="og:title" content={`${SITE_TITLE_PREFIX} ${title}`} />
      <meta key="description" property="og:description" content={description} />
      <meta name="description" content={description} />
      <meta key="site_name" property="og:site_name" content="KubeSail.com" />
      <meta key="type" property="og:type" content="website" />
      <meta key="url" property="og:url" content={url} />
      <meta key="image:type" property="og:image:type" content="image/png" />
      <meta key="image:width" property="og:image:width" content={imageWidth} />
      <meta key="image:height" property="og:image:height" content={imageHeight} />
      <meta key="image" property="og:image" content={image} />
      <meta
        key="keywords"
        name="keywords"
        content={[
          ...keywords,
          'KubeSail',
          'pibox',
          'raspberry pi',
          'Self-Hosting',
          'Kubernetes',
          'Home-Hosting',
          'home server',
          'home cluster',
          'k3s tutorials',
          'kubernetes tutorials',
        ]
          .filter(Boolean)
          .join(', ')}
      />
      <meta key="author" name="author" content="KubeSail" />
      <meta name="robots" content="index, follow" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link rel="preconnect" href="https://api.kubesail.com" />
    </Head>
  )
}

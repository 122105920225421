import React, { Component } from 'react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import fetch from 'node-fetch'
import { FaQuestionCircle } from 'react-icons/fa'
import appsScreenshot from '../assets/apps-screenshot.webp'
import backupsScreenshot from '../assets/backups-screenshot.png'
import repoBuilderScreenshot from '../assets/home-repo-builder.png'

const https = require('https')
const LRU = require('lru-cache')
const timeoutSignal = require('timeout-signal')
const { NODE_ENV, API_TARGET, templatesToShowcase } = require('../config')
const { CommonHead } = require('../util/CommonHead')
const Header = dynamic(() => import('../components/home/Header'))
const Features = dynamic(() => import('../components/home/Features'))
const Footer = dynamic(() => import('../components/home/Footer'))
const Hero = dynamic(() => import('../components/home/Hero'))
const Templates = dynamic(() => import('../components/home/Templates'))
const TrustedBy = dynamic(() => import('../components/home/TrustedBy'))
const HomeHosting = dynamic(() => import('../components/home/HomeHosting'))
const Pricing = dynamic(() => import('../components/Pricing'))
const Animation = dynamic(() => import('../components/Animation'), { ssr: false })

export default class Home extends Component {
  state = {
    currentStep: 1,
  }

  componentDidMount = () => {
    document.documentElement.setAttribute('data-theme', 'home')
  }

  componentWillUnmount = () => {
    window.detectColorScheme && window.detectColorScheme()
  }

  generateBlogHref(filename) {
    return `/blog/${filename.substr(2).replace(/\.md$/, '')}`
  }

  homeHostingSection = React.createRef()
  featuresSection = React.createRef()
  templatesSection = React.createRef()

  render() {
    return (
      <div className="Home">
        <CommonHead url="https://kubesail.com/homepage" />
        <Header
          homeHostingSection={this.homeHostingSection}
          featuresSection={this.featuresSection}
          templatesSection={this.templatesSection}
        />
        <Hero />
        <div style={{ width: '100%' }} ref={this.featuresSection}>
          <Features currentStep={this.state.currentStep} />
        </div>
        <div style={{ width: '100%' }} ref={this.homeHostingSection}>
          <HomeHosting
            currentStep={this.state.currentStep}
            setStep={currentStep => this.setState({ currentStep })}
          />
        </div>
        {/* <div style={{ width: '100%' }} ref={this.templatesSection}>
          <Templates
            currentStep={this.state.currentStep}
            fetchSpecific={templatesToShowcase}
            preloadedTemplates={this.props.preloadedTemplates}
          />
        </div> */}
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 40 }}
        >
          <Image
            alt="Manage all of your self-hosted apps in one place!"
            src={appsScreenshot}
            placeholder={'empty'}
            height={653}
            width={1087}
          />
        </div>
        <Pricing />
        <div className="FAQ">
          <div className="angle-color right blue">
            <div></div>
          </div>
          <div className="faq">
            <div className="faq-item">
              <div>
                <FaQuestionCircle />
                <h1>What is self-hosting?</h1>
              </div>
              <div>
                <a
                  rel="noreferrer nofollow"
                  target="_blank"
                  title="Wikipedia entry for self-hosting"
                  href="https://en.wikipedia.org/wiki/Self-hosting_(web_services)"
                >
                  Self-Hosting
                </a>{' '}
                means running server software yourself, on computers you own. Although every day
                billions of people use web browsers, the client side of the internet, almost all the
                servers they connect to are owned by a handful of the largest software companies in
                the world. For example, to visit Sarah's page, you don't visit Sarah's computer -
                you visit Google's or Facebook's. <br />
                <br />
                <a
                  rel="noreferrer nofollow"
                  target="_blank"
                  title="Wikipedia entry for self-hosting"
                  href="https://en.wikipedia.org/wiki/Self-hosting_(web_services)"
                >
                  Self-Hosting
                </a>{' '}
                fixes the internet by putting server-software and skills into the hands of everyone.
                Own your data, cut the expensive hosted subscription services, learn real skills:
                That's what self-hosting does!
              </div>
            </div>
          </div>
          <div className="angle-color blue">
            <div></div>
          </div>
        </div>
        <div className="trusted-by">
          <h1>Trusted by</h1>
          <div className="trusted-by-content">
            <Animation
              speed={0.85}
              animation="simple-coding"
              className="cloudguy"
              title="CloudGuy realizes he can host at home just as easily, and for far more cheaply. They should call him, like... LandGuy or something now."
            />
            <TrustedBy />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 40,
            flexWrap: 'wrap',
          }}
        >
          <Image
            alt="Build projects from GitHub on your own systems"
            src={repoBuilderScreenshot}
            placeholder={'empty'}
            height={994 / 2}
            width={525}
          />
          <Image
            alt="Backup your data to KubeSail and restore any time"
            src={backupsScreenshot}
            placeholder={'empty'}
            height={365 / 1.2}
            width={725 / 1.2}
          />
        </div>
        <Footer blogPosts={this.props.blogPosts} />
      </div>
    )
  }
}

const cacheAge = 600
const lruCache = new LRU({ ttl: cacheAge * 1000, max: 50 })

export async function getServerSideProps(context) {
  const cacheKey = 'homepage:props'
  context.res.setHeader('Cache-Control', `public, max-age=${cacheAge}, stale-while-revalidate=60`)
  context.res.setHeader('Expires', cacheAge)
  const fromCache = lruCache.get(cacheKey)
  if (fromCache) return { props: fromCache }

  const httpsAgent = new https.Agent({ rejectUnauthorized: NODE_ENV !== 'development' })
  const url = `${API_TARGET}/search/templates/${encodeURIComponent(templatesToShowcase.join('&'))}`
  const props = {}
  try {
    const res = await fetch(url, { agent: httpsAgent, signal: timeoutSignal(3000) })
    if (res.status !== 200) {
      console.warn('/homepage getServerSideProps failed, non 200 reply:', {
        status: res.status,
        url: `${API_TARGET}/search/templates`,
      })
      return { props }
    }
    const data = await res.json()
    if (data && data.templates) {
      props.preloadedTemplates = data.templates
    }
    const blogRes = await fetch(`${API_TARGET}/blog-posts?limit=5`, {
      agent: httpsAgent,
      signal: timeoutSignal(3000),
    })
    if (blogRes.status !== 200) {
      console.warn('/homepage getServerSideProps blogRes failed, non 200 reply:', {
        status: blogRes.status,
        url: `${API_TARGET}/blog-posts?limit=5`,
      })
      return { props }
    }
    const { posts } = await blogRes.json()
    if (posts) {
      props.blogPosts = posts
    }
  } catch (err) {
    console.error('/homepage getServerSideProps failed:', {
      url,
      type: err.type,
      errMsg: err.message,
      stack: err.stack,
      status: err.status,
    })
  }
  lruCache.set(cacheKey, props)
  return { props }
}
